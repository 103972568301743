import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const dynamicImportFailedMessage =
      /Failed to fetch dynamically imported module/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else if (dynamicImportFailedMessage.test(error.message)) {
      console.error('Dynamic import failed:', error.message);
      // You can handle this error more gracefully, like prompting a retry or fallback mechanism
      // For example, you could reload or redirect the user to a fallback route.
      window.location.reload();
    } else {
      console.error(error);
    }
  }
}
