import Services from './app/service';

import {
  IMAGE_LOADER,
  ImageLoaderConfig,
  NgOptimizedImage,
} from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import {
  BrowserModule,
  bootstrapApplication,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DynamicModule } from 'ng-dynamic-component';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { imageHandler } from './image-handler';

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimationsAsync(),
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      DynamicModule,
      NgOptimizedImage,
      BrowserAnimationsModule
    ),
    provideHttpClient(withFetch()),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => imageHandler(config),
    },
    ...Services,
    provideClientHydration(),
  ],
}).catch((err) => console.error(err));
