<header
  preventDefault
  *ngIf="theme?.content"
  class="d-flex container-xl flex-wrap align-items-center justify-content-lg-between py-3"
>
  <div class="col-7 col-lg-auto">
    <a [routerLink]="['/']" class="d-flex">
      <img
        width="98"
        height="30"
        placeholder
        [ngSrc]="theme?.content?.logo?.filename"
      />
    </a>
  </div>

  <ul class="nav d-none d-lg-inline-flex col-lg-auto justify-content-center">
    <ng-container *ngFor="let blok of theme?.content?.desktop_menu">
      <ng-container *ngIf="!blok.hidden">
        <li
          *ngIf="blok?.menus?.length > 0 || blok?.links?.length > 0"
          class="action btn py-3 px-x btn-link"
          [ngClass]="{ active: _active == blok }"
          (click)="openMenu(blok)"
        >
          {{ blok.label }}
        </li>
        <li
          *ngIf="!blok.menus && !blok.links"
          class="action btn py-3 px-x btn-link"
        >
          <a [storyblokLink]="blok.link">{{ blok.label }}</a>
        </li>
      </ng-container>
    </ng-container>
  </ul>

  <div
    class="icons col-5 col-lg-auto text-end justify-content-end align-items-center d-inline-flex"
  >
    <app-store-selector-toggle
      class="d-none d-lg-inline-flex"
    ></app-store-selector-toggle>
    <a class="actionable d-none d-lg-inline-block"
      ><svg
        (click)="toggleSearch()"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="7.625"
          stroke="black"
          stroke-width="1.75"
        />
        <path d="M17 17L22 22" stroke="black" stroke-width="1.75" />
      </svg>
    </a>
    <a id="openMinicart" (click)="toggleMinicart()" class="actionable cart"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.2457 18.6472L19.2574 9.05032C19.2053 8.66021 18.8933 8.34811 18.4771 8.34811H16.5786V6.42354C16.5786 3.9528 14.576 1.9502 12.1052 1.9502C9.63447 1.9502 7.63187 3.9528 7.63187 6.42354V8.34811H5.47322C5.08311 8.34811 4.74501 8.66021 4.69299 9.05032L3.67869 18.6472C3.60066 19.4795 3.88675 20.3377 4.43291 20.9619C5.00508 21.5861 5.81133 21.9502 6.64358 21.9502H17.2548C18.087 21.9502 18.8933 21.5861 19.4654 20.9879C20.0636 20.3637 20.3497 19.5055 20.2457 18.6472ZM9.19234 6.44954C9.19234 4.83706 10.4927 3.53667 12.1052 3.53667C13.7177 3.53667 15.0181 4.83706 15.0181 6.44954V8.37412H9.19234V6.44954ZM18.3471 19.9476C18.087 20.2337 17.6969 20.4157 17.3068 20.4157H6.69559C6.30547 20.4157 5.91536 20.2337 5.62927 19.9476C5.34319 19.6355 5.21315 19.2454 5.26516 18.8553L6.20144 9.9606H17.8009L18.7112 18.8553C18.7372 19.2454 18.6072 19.6355 18.3471 19.9476Z"
          fill="black"
        />

        <circle *ngIf="cart?.length > 0" cx="19" cy="4" r="4" fill="#E15F6E" />
      </svg>
    </a>
    <a
      class="mobile-menu-toggle d-lg-none d-lg-inline-block"
      (click)="toggleMobileMenu()"
      ><img width="29" height="26" src="/assets/hamburger.svg" />
    </a>
  </div>
</header>
<div class="desktop-menu" [@fadeInOut] *ngIf="_active">
  <ng-container *ngFor="let blok of theme?.content?.desktop_menu">
    <ng-container *ngIf="!blok.hidden">
      <ndc-dynamic
        *ngIf="components && blok == _active"
        [ndcDynamicComponent]="components[blok.component]"
        [ndcDynamicInputs]="blok"
      >
      </ndc-dynamic> </ng-container
  ></ng-container>
</div>
<div class="mobile-menu" [@fadeInOut] *ngIf="_activeMobile">
  <div
    class="mobile-menu d-flex flex-column pb-5 mih-100"
    [appStoryblok]="_editable"
  >
    <app-mobile-menu-commands></app-mobile-menu-commands>
    <div class="mobile-menu-inner">
      <ng-container *ngFor="let blok of theme?.content?.mobile_menu">
        <ng-container *ngIf="!blok.hidden">
          <div
            *ngIf="blok.component !== 's-link'"
            class="menu-item action d-flex flex-row justify-content-between"
            (click)="blok._active = !blok._active"
          >
            {{ blok.label }}

            <svg
              preventDefault
              (click)="blok._active = !blok._active"
              class="ms-3"
              *ngIf="(blok?.links || blok?.menus) && blok._active"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="16.3029"
                y1="17.0962"
                x2="6.40345"
                y2="7.19674"
                stroke="black"
              />
              <line
                x1="16.5962"
                y1="7.19682"
                x2="6.69673"
                y2="17.0963"
                stroke="black"
              />
            </svg>

            <svg
              preventDefault
              (click)="blok._active = !blok._active"
              class="ms-3"
              *ngIf="(blok?.links || blok?.menus) && !blok._active"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.125 19.5L15.125 12.5L8.125 5.5" stroke="black" />
            </svg>
          </div>
          <ndc-dynamic
            [ndcDynamicComponent]="components[blok.component]"
            [ndcDynamicInputs]="blok"
          >
          </ndc-dynamic>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<div preventDefault class="search-overlay" [@fadeInOut] *ngIf="_activeSearch">
  @defer (on idle; when true) {
  <app-search-overlay [@fadeInOut]></app-search-overlay>
  } @placeholder () { }
</div>
