import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GlobalErrorHandler } from './error-handler/chunk.handler';
import { CollectionResolver } from './resolver/collection.resolver';
import { GiftcardResolver } from './resolver/giftcard.resolver';
import { ProductResolver } from './resolver/product.resolver';
import { StoryblokAllBlogsResolver } from './resolver/storyblok-allblogs.resolver';
import { StoryblokBlogParentResolver } from './resolver/storyblok-blogparent.resolver';
import { StoryblokPersonaliserParentResolver } from './resolver/storyblok-personaliserparent.resolver';
import { StoryblokPersonaliserProductResolver } from './resolver/storyblok-personaliserproduct.resolver';
import { StoryblokResolver } from './resolver/storyblok.resolver';

export const routes: Routes = [
  {
    path: 'collections/:handle',
    loadComponent: () =>
      import(
        './content-source/mercury-collection/mercury-collection.component'
      ).then((m) => m.ContentSourceMercuryCollectionComponent),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      collection: CollectionResolver,
    },
  },
  {
    path: 'products/gift-card',
    loadComponent: () =>
      import(
        './content-source/shopify-giftcard/shopify-giftcard.component'
      ).then((m) => m.ContentSourceShopifyGiftcardComponent),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { product: GiftcardResolver },
  },
  {
    path: 'products/:handle',
    loadComponent: () =>
      import('./content-source/shopify-product/shopify-product.component').then(
        (m) => m.ContentSourceShopifyProductComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { product: ProductResolver },
  },
  {
    path: 'blogs',
    loadComponent: () =>
      import('./content-source/blog/all-blogs/all-blogs.component').then(
        (m) => m.AllBlogsComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { allBlogs: StoryblokAllBlogsResolver },
  },
  {
    path: 'personaliser',
    loadComponent: () =>
      import(
        './content-source/personaliser-index/personaliser-index.component'
      ).then((m) => m.PersonaliserIndexComponent),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokPersonaliserParentResolver },
  },
  {
    path: 'personaliser-product/:handle',
    loadComponent: () =>
      import(
        './content-source/personaliser-view/personaliser-view.component'
      ).then((m) => m.PersonaliserViewComponent),
    runGuardsAndResolvers: 'paramsChange',
    resolve: {
      page: StoryblokPersonaliserProductResolver,
    },
  },
  {
    path: 'blogs/:slug',
    loadComponent: () =>
      import('./content-source/blog/blog/blog.component').then(
        (m) => m.BlogComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { blogParent: StoryblokBlogParentResolver },
  },
  {
    path: 'blogs/:handle/:slug',
    loadComponent: () =>
      import('./content-source/blog/article/article.component').then(
        (m) => m.ArticleComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { article: StoryblokResolver },
  },
  {
    path: ':slug',
    loadComponent: () =>
      import('./content-source/storyblok/storyblok.component').then(
        (m) => m.ContentSourceStoryblokComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: '',
    loadComponent: () =>
      import('./content-source/storyblok/storyblok.component').then(
        (m) => m.ContentSourceStoryblokComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: '**',
    loadComponent: () =>
      import('./content-source/storyblok/storyblok.component').then(
        (m) => m.ContentSourceStoryblokComponent
      ),
    runGuardsAndResolvers: 'paramsChange',
    resolve: { page: StoryblokResolver },
  },
  {
    path: '404',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
    outlet: 'error',
  },
  {
    path: 'styleguide',
    loadComponent: () =>
      import('./components/styleguide/styleguide.component').then(
        (m) => m.StyleguideComponent
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
